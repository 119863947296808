import { SELECTED_USER } from '../../../Constants/actionTypes';

export const selectedUserAction = (user) => {
  if (!user.type) user['type'] = 'primary';
  return (dispatch) => {
    dispatch({
      type: SELECTED_USER,
      payload: { user: user },
    });
  };
};
