import React from "react"
import { LinearProgress, Box, Grid, Button, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { eligibilityErrorsMTDAction } from '../../../Redux/Actions/Widgets/eligibilityErrorsMTDAction';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '../../../Assets/Css/BasicSearchScreen.css';
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);
const EligibilityErrors = (props) => {
  const [datas, setDatas] = React.useState('payer1');
  const { totalError, getErrorTrendsMTDData, eligerr_mtddata } = props;
  const [links, setLinks] = React.useState([]);
  const objtoarr = (obj) => {
    const tempLink = [];
    if (!obj || !obj.payerTrends)
      return;
    Object.keys(obj.payerTrends).forEach(elm => {
      if (!tempLink.map(txt => txt.text).includes(elm))
        tempLink.push({ text: elm, value: obj.payerTrends[elm] });
    });
    setLinks([...[], ...tempLink])
  };
  React.useEffect(() => {
    getErrorTrendsMTDData({ range: props.mtdItem })
  }, [props.mtdItem]);
  React.useEffect(() => {
    objtoarr(eligerr_mtddata);
  }, [eligerr_mtddata]);
  const obj = eligerr_mtddata;
  let data = [
    {
      value: 48,
      color: "rgba(15, 143, 133, 1)",
      cutout: "80%",
    },
    {
      value: 12,
      color: "rgba(244, 143, 113, 1)",
      cutout: "80%",
    },
    {
      value: 100,
      color: "rgba(214, 214, 214, 1)",
      cutout: "80%",
    },
    {
      value: 34,
      color: "#0000FF",
      cutout: "80%",
    },
  ]

  const options = {
    plugins: {
      responsive: true,
    },
    cutout: data.map((item) => item.cutout),
    radius: '80%',
    layout: {
      padding: 0
    }
  };

  const finalData = {
    datasets: [
      {
        data: obj && obj.payerTrends && datas in obj.payerTrends ? Object.values(obj.payerTrends[datas]).map(Math.round) : [],
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };
  const handleChange = (event) => {
    setDatas(event.target.value);
  };
  const navigate = useNavigate();
  const handleViewAllErrors = () => {
    navigate(`${ROUTES.ERROR_RESULTS}`);
  };
  const handleMyErrors = () => {
    navigate(`${ROUTES.MY_ERROR_RESULTS}`);
  };
  return (<>
    <div className={`eligibility-error-ratio`}>
      <div className={`eligibility-error-doughnut`}>
        <Doughnut data={finalData} options={options} />
      </div>
      <div className={`eligibility-error-count`}>
        <Typography
          variant="h3"
          align="left"
        >{obj.totalErrors}</Typography>
        <Typography
          variant="h4"
          align="left"
        >total</Typography>
      </div>
    </div>
    <Grid style={{ textAlign: 'center', marginTop: 10 }} container>
      <Grid item xs={8}>{obj?.frontEndVsBackEnd?.frontEndPercentage}</Grid>
      <Grid item xs={4}>{obj?.frontEndVsBackEnd?.backEndPercentage}</Grid>
    </Grid>
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={67} />
      <div style={{ display: 'flex', justifyContent: 'center', gap: '200px', marginTop: 10 }}>
        <p>Front-end</p>
        <p>Back-end</p>
      </div>
    </Box>
    <Typography
      variant="h6"
      align="left"
      gutterBottom="true"
      sx={{ marginTop: '10px' }}
    >select by payer</Typography>
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">view all payers</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={datas}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value="payer1">Payer1</MenuItem>
          <MenuItem value="payer2">Payer2</MenuItem>
        </Select>
      </FormControl>
    </Box>
    {links.filter(item => item.text === datas).map(item => (
      <Grid container>
        <Grid item className={`error-container  common-line `} xs={11}>
          <a href="#" style={{ textDecoration: 'none', backgroundColor: '#1976d2', color: 'white', borderRadius: '4px', padding: '8px, 16px' }}
          >
            Front-end errors</a>
        </Grid>
        <Grid item className={`text-right error-container common-line`} xs={1}> {item.value.totalErrors}</Grid>
        <Grid item className={`error-container  common-line `} xs={11}>
          <a href="#" style={{ textDecoration: 'none', backgroundColor: '#F08080', color: 'white', borderRadius: '4px', padding: '4px, 8px' }}
          >
            Unassigned back-end errors</a>
        </Grid>
        <Grid item className={`text-right error-container common-line`} xs={1}>{item.value.unassigned}</Grid>
        <Grid item className={`error-container   common-line`} xs={11}>
          <a href="#" style={{ textDecoration: 'none', backgroundColor: '#008000', color: 'white', borderRadius: '4px', padding: '4px, 8px' }}
          >
            assigned back-end errors</a>
        </Grid>
        <Grid item className={`text-right error-container common-line`} xs={1}> {item.value.assigned}</Grid>
        <Grid item className={`error-container  common-line `} xs={11}>
          <a href="#" style={{ textDecoration: 'none', backgroundColor: '#D3D3D3', color: 'black', borderRadius: '4px', padding: '4px, 8px' }}
          >
            Completed errors</a>
        </Grid>
        <Grid item className={`text-right error-container common-line`} xs={1}> {item.value.completed}</Grid>
      </Grid>
    ))
    }

    <Grid item xs={12} className="eligibility-error-btn">
      <Button className={`eligibility-search-btn`} variant="text" onClick={handleViewAllErrors}>
        View all errors
          </Button>
      <Button className={`view-my-errors`} variant="contained" color="success" onClick={handleMyErrors}>
        View my errors
          </Button>
    </Grid>
  </>)
}
const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  getErrorTrendsMTDData: eligibilityErrorsMTDAction
};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrors);