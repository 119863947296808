export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const adminFePayloadToBePayload = (payload = {}) => {
  const { User, TeamLeader, TeamLead, Name } = payload;
  return {
    userId: User,
    isTeamLead: TeamLead === 'Yes' ? 1 : 0,
    name: Name,
    teamLeadId: TeamLeader === '-' ? '' : TeamLeader,
  };
};

export const adminBePayloadToFePayload = (admin_user = []) => {
  return (admin_user || [])?.reduce(
    (accu, { userId, isTeamLead, name, teamLeadId }) => {
      const dataMapping = {
        User: userId,
        TeamLead: parseInt(isTeamLead) ? 'Yes' : 'No',
        Name: name,
        TeamLeader: parseInt(isTeamLead) ? '-' : teamLeadId || '-',
        Delete: '',
      };
      if (
        dataMapping.User &&
        !accu.map((e) => e.User).includes(dataMapping.User)
      )
        accu.push(dataMapping);
      return accu;
    },
    [],
  );
};

export const securitySavePayload = (payload = {}) => {
  return {
    userId: payload.User,
    assigned: payload.AssignedTpaIds.join(','),
  };
};

export const getTPAName = (tpaId = [], arr = []) => {
  if (typeof tpaId === 'string')
    return `${arr?.find((e) => e.tpaId === tpaId)?.tpaName || ''}, `?.rtrim(
      ',',
    );
  return tpaId.reduce((acc, elm, i) => {
    return (acc += `${arr?.find((e) => e.tpaId === elm)?.tpaName || ''} ${i === tpaId.length - 1 ? '' : ', '}`);
  }, '');
};

export const getDifferance = (arr1, arr2) => {
  return arr1.filter((element) =>
    arr2.every((value) => JSON.stringify(value) !== JSON.stringify(element)),
  );
};

export const validateAdminData = (data) => {
  let returnData = true;
  if (!data.User) returnData = false;
  if (data.TeamLead === 'No' && (!data.TeamLeader || data.TeamLeader === '-'))
    returnData = false;
  return returnData;
};
