export const PRODUCT_MODAL = {
  TITLE: {
    PRODUCT_LIST: 'Product list',
  },
};

export const colDef = [
  {
    headerName: 'Product ID',
    field: 'productId',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Description',
    field: 'description',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Rule Set Prt',
    field: 'ruleSet',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Effective Date',
    field: 'effectiveDate',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Termination Date',
    field: 'terminationDate',
    sortable: true,
    unSortIcon: true,
  },
];
export const gridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 10,
  },
};
export const defaultCofDef = {
  flex: 1,
  minWidth: 10,
};
