import {
  GET_ADMIN_USER,
  GET_ADMIN_TPA_DATA,
  GET_ADMIN_USER_TPA_DATA,
} from '../../../Constants/actionTypes';

export const getAdminReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ADMIN_USER:
      return action?.payload || [];
    default:
      return state;
  }
};

export const getTPAReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ADMIN_TPA_DATA:
      return action?.payload || [];
    default:
      return state;
  }
};

export const getUserTPAReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ADMIN_USER_TPA_DATA:
      return action?.payload || [];
    default:
      return state;
  }
};
