import React from "react"
import Icon from "../../Common/Icon"

const HelpfulLinks = (props) => {
    const { links } = props;
    return (<>
        {
            links.map(link => {
                return (
                    <p key={`${JSON.stringify(link)}-${props.currIndex}`} id={`admin-security-setup-p-${props.currIndex}`}>
                        <a id={`admin-security-setup-a-${props.currIndex}`}
                            className={`widget-anchor`} href="#">
                            {link.text}
                            <Icon name="ArrowOutwardIcon" />
                        </a>
                    </p>)
            })
        }
    </>)
}

export default HelpfulLinks