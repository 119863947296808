import {
  FETCHING_DATA,
  GET_MEMBER_PRODUCTS,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const getMembershipProductAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_MEMBER_PRODUCTS),
      },
    });
    await apiAction(dispatch, GET_MEMBER_PRODUCTS, payload);
  };
};
