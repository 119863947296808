import * as React from "react"
import { isWidget } from "../../Constants/widgetConstant"
import { Card } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Widget from "./Widget"

const WidgetBody = (props) => {
  const { currentElement, currIndex } = props;
  const { component } = currentElement
  return (
    <>
      {
        isWidget(component) ?
          <Card>
              <CardContent sx={{padding: '0px 20px'}}>
              {component ? <Widget component={component} currIndex={currIndex} /> : <></>}
            </CardContent>
          </Card>
          :
          <></>
      }
    </>)
}

export default WidgetBody;