import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import AdvancedSearchScreen from './AdvancedSearchScreen';
import "../../../Assets/Css/BasicSearchScreen.css";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import Icon from "../../Common/Icon"
import CustomModal from '../../Common/CustomModal';
import { emptyObject } from "../../../Utils/Common/common.util"

import { inputAction } from "../../../Redux/Actions/Common/inputAction"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { ROUTES } from "../../../Constants/routesConstant"
import { Grid, Button } from "@mui/material";

const BasicSearchScreen = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ firstName: '', lastName: '', birthday: '' });
  const [errorMsg, setErrorMsg] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const FirstNameRef = useRef(null);
  const LastNameRef = useRef(null);
  const BirthdayRef = useRef(null);
  const AdvancedSearchScreenButtonRef = useRef(null);
  const SearchButtonRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrorMsg("");
  };
  const handleBasicSearch = async (e) => {
    e.preventDefault();
    const { firstName, lastName, birthday } = formData;
    const validCombinations = ((firstName && lastName)
      || (firstName && lastName && birthday)
      || (lastName));
    if (!validCombinations) {
      setErrorMsg("Please click on 'Help' icon to know valid combinations to be entered.");
      setTimeout(() => setErrorMsg(''), 3000);
      return;
    }
    props.input({ ...props.component_input, 'search': formData })
    if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
      const searchQuery = {};
      if (firstName) {
        searchQuery['firstName:startsWith'] = firstName;
      }
      if (lastName) {
        searchQuery['lastName:startsWith'] = lastName;
      }
      if (birthday) {
        searchQuery['birthday'] = birthday;
      }
      await props.getSearchData(searchQuery)
      navigate(`${ROUTES.SUBSCRIBER.SEARCH_RESULT}`);
    }
  };

  const handleAdvancedSearch = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleHelp = () => {
    setOpenPopup(true);
  };
  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const handleTabNavigation = (e, ref) => {
    if (e.key === 'Tab' && ref.current === document.activeElement) {
      e.preventDefault();
      if (ref === BirthdayRef) {
        AdvancedSearchScreenButtonRef.current.focus();
      } else {
        ref.current.focus();
      }
      if (ref === AdvancedSearchScreenButtonRef) {
        SearchButtonRef.current.focus();
      }
    }
  };
  const combinations = [
    "lastName, firstName",
    "lastName, firstName, birthDate",
    "lastName"
  ];
  const prepareContent = (combinations) => {
    return (
      <ul>
        {combinations.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const content = prepareContent(combinations);

  return (
    <>
      {/* <div className="BasicSearch"> */}
      <div className="Basicsearchform">     

        <div className="basic-form-group-wrp">
          <div className="form-group">
            <p>Last name</p>
            <input 
              className="form-group"
              type="text"
              placeholder="Enter last name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              ref={FirstNameRef}
              onKeyDown={(e) => handleTabNavigation(e, LastNameRef)}
            />
          </div>
          <div className="form-group">
            <p>First name(optional)</p>
            <input
              type="text"
              placeholder="Enter first name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              ref={LastNameRef}
              onKeyDown={(e) => handleTabNavigation(e, BirthdayRef)}
            />
          </div>
          <div className="form-group">
          <p className="title">Date of Birth(optional)</p>
          <input
            className="form-group-date"
            type="date"
            placeholder="Enter Date of Birth"
            name="birthday"
            value={formData.birthday}
            onChange={handleChange}
            ref={BirthdayRef}
            onKeyDown={(e) => handleTabNavigation(e, BirthdayRef)}
          />
        </div> 
        </div>       
        <Grid item xs={12} className="eligibility-error-btn">
          <Button className={`eligibility-search-btn`} variant="text" onClick={handleAdvancedSearch} >
            Advanced search
          </Button>
          <Button className={`view-all-error`} variant="contained" color="success" onClick={handleBasicSearch}>
            Search
          </Button>
        </Grid>
        {errorMsg && (
          <div className="errmsg" id="errormsgs">
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMsg}
            </Alert>
          </div>
        )}
      </div>
      <Modal
        open={showPopup}
        onClose={handleClose}
        aria-labelledby="advanced-modal-title"
        aria-describedby="advanced-modal-description"
        fullWidth maxWidth="md"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box className="modal">
          <AdvancedSearchScreen />
        </Box>
      </Modal>
      <CustomModal
        open={openPopup}
        handleClose={handlePopupClose}
        title="Basic Search Combinations"
        content={content}
      />

      {/* </div> */}
    </>
  )
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  input: inputAction,
  getSearchData: membershipSearchAction
};

export default connect(mapStateToProps, mapActionToProps)(BasicSearchScreen);

