export const ROUTES = {
  DASHBOARD: '/',
  SUBSCRIBER: {
    SUBSCRIBER_DETAILS: '/subscriber/details',
    DEPENDENT_DETAILS: '/dependent/details',
    SEARCH_RESULT: '/subscriber/search',
  },
  AUTHORIZATION_CALLBACK: '/authorization-code/callback',
  SEARCH: '/searchscreen',
  ERROR_TRENDS: '/Dashboard/errortrends',
  ERROR_RESULTS: '/Dashboard/error-results',
  MY_ERROR_RESULTS:'/Dashboard/my-error-results',
  FILE_TRACKING: '/Dashboard/filetracking',
  FILE_SEARCH: '/Dashboard/fileSearch',
  ADMIN_SECURITY: '/admin',
  NOT_FOUND: '*',
  ACCESS_DENIED: '/access-denied',
};

export const DENIED_PATH = [];

export const HIDE_LEFT_MENU_ROUTES = [ROUTES.DASHBOARD];