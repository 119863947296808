import {
  FETCHING_DATA,
  ELIGIBILITY_ERRORS_MTD_API,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const eligibilityErrorsMTDAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === ELIGIBILITY_ERRORS_MTD_API),
      },
    });
    await apiAction(dispatch, ELIGIBILITY_ERRORS_MTD_API, payload);
  };
};
