import React from 'react';
import { MenuItem, FormControl, Select } from '@mui/material';

const SelectDropdown = (params) => {
    const { value, colDef, data: { TeamLeader, TeamLead }, uniqueTeamLeader } = params;


    const handleChange = (event) => {
        const newValue = event.target.value;
        params.node.setDataValue(colDef.field, newValue);
    };

    return (
        <FormControl fullWidth size="small">
            {!params.isEditable ?
                <>{params.data.TeamLeader}</>
                : (TeamLead === "No")
                    ?
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={[...new Set(uniqueTeamLeader)].includes(value) ? value : ''}
                        label="TeamLeader"
                        className={TeamLead === "No" && (!TeamLeader || TeamLeader === "-") ? 'error' : ''}
                        onChange={handleChange}
                    >
                        {[...new Set(uniqueTeamLeader)]?.map((option) => (
                            <MenuItem key={`${option}-TeamLead`} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                    :
                    [...new Set(uniqueTeamLeader)][0]}
        </FormControl>)
}

export default SelectDropdown;