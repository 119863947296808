import { MEMBERSHIP_API } from '../../../Constants/actionTypes';

export const membershipReducer = (state = null, action) => {
  switch (action.type) {
    case MEMBERSHIP_API:
      return action.payload;
    default:
      return state;
  }
};
