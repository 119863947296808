import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Icon from "../../Common/Icon"
import IconButton from '@mui/material/IconButton';
import { emptyObject } from "../../../Utils/Common/common.util"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { selectedUserAction } from "../../../Redux/Actions/Membership/selectedUserAction"
import { ROUTES } from "../../../Constants/routesConstant"
// import CustomModal from "../Component/Common/CustomModal";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const MembershipSearchResults = (props) => {
  const [data, setData] = useState([])
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showColumnMenu, setShowColumnMenu] = useState(false);
  const [previousVisibleColumns, setPreviousVisibleColumns] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const gridRef = useRef(null);
  const gridApiRef = useRef(null);
  const columnMenuRef = useRef(null);
  const getData = (data) => {
    props.doSetUser({...data.data, ...(data.data.memberId && {membershipId: data.data.memberId})})
    navigate(`${ROUTES.SUBSCRIBER.SUBSCRIBER_DETAILS}`)
  }
  const CustomButtonComponent = (idparam) => {
    return <button onClick={() => getData(idparam)} style={{ color: 'blue', border: 'none', cursor: 'pointer' }}>View</button>
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'PayerId', field: 'payerId', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Group Id', field: 'groupId', sortable: true, order: 1, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'First Name', field: 'firstName', sortable: true, order: 2, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Last Name', field: 'LastName', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        closeOnApply: true,
        buttons: ['reset', 'apply'],
      }
    },
    {
      headerName: 'SSN', field: 'SSN', sortable: true, order: 4, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Person Number', field: 'personNumber', sortable: true, order: 5, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Member Id', field: 'memberId', sortable: true, order: 6, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Participant Type', field: 'participantType', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'City', field: 'city', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'State', field: 'state', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Zipcode', field: 'zipcode', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Gender', field: 'gender', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Address', field: 'address', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    {
      headerName: 'Birthday', field: 'birthday', sortable: true, filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressMiniFilter: false,
        selectAllowMiniFilter: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      }
    },
    { headerName: 'Actions', field: 'Button', cellRenderer: CustomButtonComponent, flex: 1, filter: false, sortable: false },
  ]);

  React.useEffect(() => {
    if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
      const { firstName, lastName, birthday, postalCode, state, personNumber, city, gender, membershipId, groupId } = props?.component_input?.search;
      const searchQuery = {};
      if (firstName) {
        searchQuery['firstName:startsWith'] = firstName;
      }
      if (lastName) {
        searchQuery['lastName:startsWith'] = lastName;
      }
      if (groupId) {
        searchQuery['groupId'] = groupId;
      }
      if (birthday) {
        searchQuery['birthday'] = birthday;
      }
      if (postalCode) {
        searchQuery['postalCode'] = postalCode;
      }
      if (state) {
        searchQuery['state'] = state;
      }
      if (membershipId) {
        searchQuery['membershipId'] = membershipId;
      }
      if (personNumber) {
        searchQuery['personNumber'] = personNumber;
      }
      if (city) {
        searchQuery['city'] = city;
      }
      if (gender) {
        searchQuery['gender'] = gender;
      }
      props.getSearchData(searchQuery)
    }
    else navigate("/")
    return () => {
      setData([])
    };
  }, [props?.component_input?.search])
  const handleApply = () => {
    setShowColumnMenu(false);
  };
  const handleReset = () => {
    const updatedColumns = columnDefs.map(column => ({
      ...column,
      hide: false
    }));
    setColumnDefs(updatedColumns);
    setShowColumnMenu(false);
  };
  useEffect(() => {
    if (gridApi && gridColumnApi) {
      gridColumnApi.getAllColumns().forEach((column) => {
      });
    }
    function handleClickOutside(event) {
      if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
        setShowColumnMenu(false);
      }
    }
    setData(props?.member_list?.error ? [] : props?.member_list)
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [gridApi, gridColumnApi]);
  const [columnOrder, setColumnOrder] = useState(columnDefs.map(col => col.field));
  useEffect(() => {
    const savedColumnOrder = JSON.parse(localStorage.getItem('columnOrder'));
    if (savedColumnOrder) {
      const updatedColumnDefs = savedColumnOrder.map(field =>
        columnDefs.find(colDef => colDef.field === field)
      );
      setColumnDefs(updatedColumnDefs);
      setColumnOrder(savedColumnOrder);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('columnOrder', JSON.stringify(columnOrder));
  }, [columnOrder]);
  //handling refresh scenarios for column order

  const onColumnMoved = () => {
    if (gridRef.current) {
      const allColumnState = gridRef.current.getColumnState();
      const newColumnOrder = allColumnState.map(columnState => columnState.colId); // Use colId for tracking
      setColumnOrder(newColumnOrder);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridRef.current = params.api;
    params.api.addEventListener('columnMoved', onColumnMoved);

    // Initialize grid with saved column definitions
    const savedColumnOrder = JSON.parse(localStorage.getItem('columnOrder'));
    if (savedColumnOrder) {
      const newColumnDefs = savedColumnOrder.map(field =>
        columnDefs.find(colDef => colDef.field === field)
      );
      if (params.api.setColumnDefs) {
        params.api.setColumnDefs(newColumnDefs);
      } else {
        console.error("setColumnDefs is not available on params.api");
      }
    }
  };
  //handling refresh scenarios for column order
  const toggleColumnMenu = () => {
    setShowColumnMenu(!showColumnMenu);
  };
  const toggleColumn = (columnName, checked) => {
    const newColumnDefs = columnDefs.map(column => {
      if (column.field === columnName) {
        return { ...column, hide: !checked };
      }
      return column;
    });
    setColumnDefs(newColumnDefs);
  };
  const gridOptions = {
    suppressBorders: true,
    defaultColDef: {
      minWidth: 140,
    },
  };
  const exportDataGridToExcel = (columnDefs, data, fileName) => {
    const headers = columnDefs.filter(col => col.field !== 'Button').map(col => col.headerName);
    const mappedData = data.map(row => {
      let mappedRow = {};
      columnDefs.forEach(col => {
        if (col.field !== 'Button') {
          mappedRow[col.headerName] = row[col.field]
        }
      });
      return mappedRow;

    });
    const worksheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };
  const handleExportExcel = () => {
    const visibleColumns = columnDefs.filter(col => !col.hide);
    const visibleColumnFields = visibleColumns.map(col => col.field);
    const filteredData = data.map(row => {
      const filteredRow = {};
      visibleColumnFields.forEach(field => {
        if (field !== 'Button') {
          filteredRow[field] = row[field];
        }
      });
      return filteredRow;
    })
    exportDataGridToExcel(visibleColumns, filteredData, 'SearchResults.xlsx');
  };
  const content = "Download the results as an excel file";
  return (
    <div className="ag-theme-alpine" style={{ minHeight: '100vh', width: '100%', padding: '40px', marginTop: '50px' }}>
      <div >
        <div style={{ right: '45px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <button
            style={{
              border: '1px solid #035c67',
              backgroundColor: 'white',
              padding: '5px 10px',
              cursor: 'pointer',
              borderRadius: '4px',
              marginbottom: '10px',
              left: '10px',
              color: ' #035c67',
              width: '150px'
            }}
            onClick={toggleColumnMenu}
          >
            Edit Columns
      </button>
          <IconButton onClick={handleExportExcel} style={{ color: 'blue', fontSize: 15, border: 'none', outline: 'none', background: 'none', borderRadius: 0 }}>
            <p>Download Results </p>
            <Icon name="ArrowDownwardIcon" />
          </IconButton>
        </div>
      </div>
      {showColumnMenu && (
        <div
          style={{
            position: 'absolute',
            top: '243px',
            left: '12px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            zIndex: '999',
            margin: '35px'
          }}
        >
          <strong> Columns</strong>
          {columnDefs.map((column) => (
            <div key={column.field} style={{ marginTop: '5px' }}>
              <label style={{ cursor: 'pointer' }}>
                <input
                  type="checkbox"
                  checked={!column.hide}
                  onChange={(e) => toggleColumn(column.field, e.target.checked)}
                  disabled={column.headerName === 'Actions'}
                  style={{
                    marginRight: '15px', appearence: 'none',
                    backgroundColor: '#035c67',
                    width: '14px',
                    height: '14px',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    colour: '#035c67',
                    top: '12px'
                  }}
                />
                {column.headerName}
              </label>
            </div>
          ))}
          <div style={{ marginTop: '10px' }}>
            <button onClick={handleReset} style={{ marginRight: '10px', border: 'none', color: 'blue', backgroundColor: 'white', cursor: 'pointer' }} >Reset</button>
            <button onClick={handleApply} style={{ marginRight: '10px', backgroundColor: '#035c67', color: 'white', border: 'none', cursor: 'pointer' }}> Apply </button>
          </div>
        </div>
      )}
      <AgGridReact
        onGridReady={onGridReady}
        rowData={data}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        onColumnMoved={onColumnMoved}
        suppressDragLeaveHidesColumns={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 50]}
        domLayout='autoHeight'
        enableColumnHandling
        ref={(grid) => { gridApiRef.current = grid }}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  doSetUser: selectedUserAction,
  getSearchData: membershipSearchAction
};

export default connect(mapStateToProps, mapActionToProps)(MembershipSearchResults);
