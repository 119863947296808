import React from "react"
import { Grid, Button } from "@mui/material";
import Icon from "../../Common/Icon"
import '../../../Assets/Css/Dashboard.css';
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate } from "react-router-dom";

const PayerEligibilityFileTracking = (props) => {
    const { links } = props;
    const navigate = useNavigate();
    const handleViewTracking=()=>{
        navigate(`${ROUTES.FILE_TRACKING}`);
    };
    return (<>
    <Icon className="dashboard-icons" />
        <Grid item className={`error-container `} xs={11}>Files recieved by Payer</Grid>
        {links.map(link => {
            return (
                <Grid /*className={`pd-20 current-index-${props.currIndex}`}*/ container key={JSON.stringify(link)}>
                    <Grid item className={`error-container widget-anchor common-line`} xs={11}>
                        <a href="#"
                            className={`widget-anchor`}>
                            {link.text}</a>
                    </Grid>
                    <Grid item className={`text-right error-container common-line`} xs={1}>{link.value}</Grid>
                </Grid>
            )
        })}
        <Grid item className={`error-container widget-anchor common-line view-tracking-info`}  xs={11} onClick={handleViewTracking}>
            View Tracking Information
            <Icon name="ArrowForwardOutlinedIcon" style = {{cursor:'pointer'}} />
        </Grid>
        <Grid item className={`text-right error-container common-line border-buttom`} xs={1}></Grid>

    </>)
}

export default PayerEligibilityFileTracking;