import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../Assets/Css/EligibilityErrorTrends.css"
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Icon from "../../Common/Icon";
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import widgetConstant from '../../../Constants/widgetConstant';
import { errorTrendsMTDAction } from '../../../Redux/Actions/Widgets/errorTrendsMTDAction';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const EligibilityErrorResults = (props) => {
    const items = ['TODAY', 'Last Business Day', 'MTD', 'Previous Month', 'QTD', 'Previous Quarter', 'YTD']
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    //const [data, setData] = useState([])

    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showColumnMenu, setShowColumnMenu] = useState(false);
    const [previousVisibleColumns, setPreviousVisibleColumns] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedName, setSelectedName] = useState(items[0]);

    //const [selectedPayerNames, setSelectedPayerNames] = useState([]);
    const location = useLocation();
    const searchParams = location.pathname.split('/');
    const filter = searchParams[2];
    const query = useQuery();
    const gridRef = useRef(null);
    const gridApiRef = useRef(null);
    const columnMenuRef = useRef(null);
    // const url = window.location.href;
    const datas = [{
        id: 1,
        Payername: 'Group Administrators, LTD',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Pravallika',
        status: 'Complete'

    },
    {
        id: 2,
        Payername: 'The Lewer Agency, INC',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'smith',
        status: 'Back-end error'
    },
    {
        id: 3,
        Payername: 'Capital Administrators SE',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Mark',
        status: 'Back-end error'
    },
    {
        id: 4,
        Payername: 'Key Benifit Administrators, INC',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Pravallika',
        status: 'Complete'
    },
    {
        id: 5,
        Payername: 'Lucent Health California Division',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'stepp',
        status: 'Complete'
    },
    {
        id: 6,
        Payername: 'Health scope Benifits',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Adam',
        status: 'Back-end error'
    },
    {
        id: 7,
        Payername: 'American Republic Insurance Company',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Alex',
        status: 'InComplete'
    },
    {
        id: 8,
        Payername: 'Allegiance, Benifit Plan Management, INC',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Pravallika',
        status: 'Back-end error'
    },
    {
        id: 9,
        Payername: 'EBIX Health Administration, INC',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Smith',
        status: 'Front-end error'
    },
    {
        id: 10,
        Payername: 'Ameriben/IEC group',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Dwerk',
        status: 'InComplete'
    },
    {
        id: 11,
        Payername: 'Assurant Health -Best',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Kime',
        status: 'Back-end error'
    },
    {
        id: 12,
        Payername: 'Assurant Health-Aces',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Pravallika',
        status: 'Complete'
    },
    {
        id: 13,
        Payername: 'Health Smart West',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Alex',
        status: 'Front-end error'
    },
    {
        id: 14,
        Payername: 'Meritain DG ',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Steves',
        status: 'InComplete'
    },
    {
        id: 15,
        Payername: 'South Point Benifits',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Pravallika',
        status: 'Back-end error'
    },
    {
        id: 16,
        Payername: 'Health Smart west',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Kail',
        status: 'Back-end error'
    },
    {
        id: 17,
        Payername: 'Health Scope staff',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Pravallika',
        status: 'Front-end error'
    },
    {
        id: 18,
        Payername: 'KBA/Assurant self-funded',
        GroupId: '1234567',
        errortype: 'ABCD',
        AMI: '1234567',
        MemberId: '57849',
        Firstname: 'ABCD',
        Lastname: 'smith',
        DOB: '12-2-1987',
        assignee: 'Rouger',
        status: 'InComplete'
    }
    ]
    const [rowData, setRowData] = useState(datas);
    // useEffect(() => {
    //     //setData(datas);
    //     setFilteredData(datas);
    // }, []);
    const isButtonDisabled = filter === 'error-results';
    useEffect(() => {
        if (filter === 'my-error-results') {
            setRowData(datas.filter((item) => item.assignee === 'Pravallika'));

        } else {
            setRowData(datas);
        }
    }, [filter]);
    const handleItemClick = async (itemId) => {
        setSelectedItem(itemId);
        setSelectedName(itemId);
        setDropDownVisible(false);
    };
    const handleClick = (e) => {
        setDropDownVisible(!dropDownVisible);
    };
    const CustomButtonComponent = (idparam) => {
        return <button onClick={() => getData(idparam)} style={{ color: 'blue', border: 'none', cursor: 'pointer' }}>View</button>
    };
    const [columnDeffs, setcolumnDeffs] = useState([
        {
            headerName: 'Payer name', field: 'Payername', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        },
        {
            headerName: 'Assignee', field: 'assignee', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        },
        {
            headerName: 'Status', field: 'status', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        },
        {
            headerName: 'Group ID', field: 'GroupId', sortable: true, order: 1, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Error type', field: 'errortype', sortable: true, order: 2, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'AMI', field: 'AMI', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
            filterParams: {
                applyMiniFilterWhileTyping: true,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                closeOnApply: true,
                buttons: ['reset', 'apply'],
            }
        },
        {
            headerName: 'Member ID', field: 'MemberId', sortable: true, order: 4, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'First name', field: 'Firstname', sortable: true, order: 5, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Last name', field: 'Lastname', sortable: true, order: 5, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'DOB', field: 'DOB', sortable: true, order: 5, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        { headerName: 'Actions', field: 'Button', cellRenderer: CustomButtonComponent, flex: 1, filter: false, sortable: false },
    ]);
    const handleApply = () => {
        setShowColumnMenu(false);
    };
    const handleReset = () => {
        const updatedColumns = columnDeffs.map(column => ({
            ...column,
            hide: false
        }));
        setcolumnDeffs(updatedColumns);
        setShowColumnMenu(false);
    };
    useEffect(() => {
        if (gridApi && gridColumnApi) {
            gridColumnApi.getAllColumns().forEach((column) => {
            });
        }
        function handleClickOutside(event) {
            if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
                setShowColumnMenu(false);
            }
        }
        // setData(props?.member_list?.error ? [] : props?.member_list)
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [gridApi, gridColumnApi]);
    const onColumnMoved = () => {
        if (gridRef.current) {
            const allColumnState = gridRef.current.getColumnState();
            const newColumnOrder = allColumnState.map(columnState => columnState.colId); // Use colId for tracking
        }
    };
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        gridRef.current = params.api;
        params.api.addEventListener('columnMoved', onColumnMoved);
        const savedColumnOrder = JSON.parse(localStorage.getItem('columnOrder'));
        if (savedColumnOrder) {
            const newcolumnDeffs = savedColumnOrder.map(field =>
                columnDeffs.find(colDef => colDef.field === field)
            );
            if (params.api.setcolumnDeffs) {
                params.api.setcolumnDeffs(newcolumnDeffs);
            } else {
                console.error("setcolumnDeffs is not available on params.api");
            }
        }
    };
    const toggleColumnMenu = () => {
        setShowColumnMenu(!showColumnMenu);
    };
    const toggleColumn = (columnName, checked) => {
        const newcolumnDeffs = columnDeffs.map(column => {
            if (column.field === columnName) {
                return { ...column, hide: !checked };
            }
            return column;
        });
        setcolumnDeffs(newcolumnDeffs);
    };
    const gridOptions = {
        suppressBorders: true,
        defaultColDef: {
            minWidth: 300,
        },
    };
    const exportDataGridToExcel = (columnDeffs, datas, fileName) => {
        const headers = columnDeffs.filter(col => col.field !== 'Button').map(col => col.headerName);
        const mappedData = datas.map(row => {
            let mappedRow = {};
            columnDeffs.forEach(col => {
                if (col.field !== 'Button') {
                    mappedRow[col.headerName] = row[col.field]
                }
            });

            return mappedRow;

        });
        const worksheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, fileName);
    };
    const handleExportExcel = () => {
        const visibleColumns = columnDeffs.filter(col => !col.hide);
        const visibleColumnFields = visibleColumns.map(col => col.headerName);
        const filteredData = datas.map(row => {
            const filteredRow = {};
            visibleColumnFields.forEach(field => {
                if (field !== 'Button') {
                    filteredRow[field] = row[field];
                }
            });
            return filteredRow;
        })
        exportDataGridToExcel(visibleColumns, datas, 'Error Trends.xlsx');//changed filtered data to  data as pointing to mock
    };
    const handleAssignToMe = () => {
        const updatedData = rowData.map((row) => ({
            ...row,
            assignee: 'Pravallika'
        }));
        setRowData(updatedData);
    }
    const handleMarkAllAsComplete = () => {
        const updatedData = rowData.map((row) => ({
            ...row,
            status: 'Complete'
        }));
        setRowData(updatedData);

    }
    return (
        <>
            <h1 className="searchtitle">Eligibility errors and warnings</h1>
            <div className="line"></div>
            <Box sx={{ width: '100%' }}>
                <div className="ag-theme-alpine error-trends">
                    <div >
                        <div className="left-buttons">
                            <button className="edit-columns"
                                onClick={toggleColumnMenu}
                            >
                                Edit Columns
                         </button>
                            <button className="mtd-list"
                                onClick={handleClick}
                            >
                                {selectedName}
                            </button>
                            {dropDownVisible &&
                                <ul className="dropdown">
                                    {items.map((item, index) => (
                                        <li key={index} className="mtd-dropdown-li-ob" onClick={() => handleItemClick(item)}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            }
                            <IconButton className="assign-to-me" onClick={handleAssignToMe} >
                                <p>Assign all to me </p>
                                <Icon name="PermIdentityOutlinedIcon" className="arrow-icon" />
                            </IconButton>
                            <IconButton className="download-results"
                                onClick={handleMarkAllAsComplete}
                                disabled={isButtonDisabled}>
                                {/* style={{Color: isButtonDisabled?'grey':'#035c67'}}> */}
                                <p>Mark all as complete </p>
                                <Icon name="DoneIcon" className="arrow-icon" />
                            </IconButton>
                            <IconButton className="download-results" onClick={handleExportExcel}>
                                <p>Download Results </p>
                                <Icon name="ArrowDownwardIcon" className="arrow-icon" />
                            </IconButton>
                        </div>
                    </div>
                    {showColumnMenu && (
                        <div className="columns">

                            <strong> Columns</strong>
                            {columnDeffs.map((column) => (
                                <div key={column.field} className="column-margin">
                                    <label className="checkbox-pointer">
                                        <input
                                            type="checkbox"
                                            checked={!column.hide}
                                            onChange={(e) => toggleColumn(column.field, e.target.checked)}
                                            disabled={column.headerName === 'Actions'}
                                            classNames="column-names"
                                        />
                                        {column.headerName}
                                    </label>
                                </div>
                            ))}
                            <div className="edit-columns-buttons">
                                <button onClick={handleReset} className="reset-button" >Reset</button>
                                <button onClick={handleApply} className="apply-button"> Apply </button>
                            </div>
                        </div>
                    )}

                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columnDeffs}
                        gridOptions={gridOptions}
                        onColumnMoved={onColumnMoved}
                        suppressDragLeaveHidesColumns={true}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 15, 20, 50]}
                        domLayout='autoHeight'
                        enableColumnHandling
                        ref={(grid) => { gridApiRef.current = grid }}
                        getRowId={(params) => params.data.id}
                    />
                </div>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getMTDData: errorTrendsMTDAction
};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrorResults);