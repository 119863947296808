import { SAVE_OKTA_TOKEN } from '../../../Constants/actionTypes';

export const oktaTokenAction = (okta = {}) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_OKTA_TOKEN,
      payload: {
        ...okta,
      },
    });
  };
};
