import {
  MEMBERSHIP_SEARCH_API,
  MEMBERSHIP_API,
  DEPENDENT_API,
  TOKEN_GENERATION_API,
  TOKEN_VERIFY_API,
  GET_DASHBOARD_SETTINGS,
  SAVE_DASHBOARD_SETTINGS,
  GET_ADMIN_USER,
  GET_ADMIN_TPA_DATA,
  SAVE_ADMIN_TPA_DATA,
  SAVE_ADMIN_USER,
  GET_ADMIN_USER_TPA_DATA,
  DELETE_ADMIN_USER,
  CREATE_ADMIN_USER,
  ERROR_TRENDS_MTD_API,
  ELIGIBILITY_ERRORS_MTD_API,
  PAYER_FILE_RATIO_MTD_API,
  SAVE_ADMIN_USER_TPA_DATA,
  GET_MEMBER_PRODUCTS,
} from './actionTypes';

export const API_TYPE = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};
export const BODY_TYPE = {
  RAW: 'raw',
  FORM_DATA: 'form-data',
  X_WWW_FORM_URLENCODED: 'x-www-form-urlencoded',
};
const APIS = [
  {
    NAME: MEMBERSHIP_SEARCH_API,
    PATH: 'member/search',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: MEMBERSHIP_SEARCH_API,
    RESPONSE: {},
    DATA: { todos: [], total: 0, skip: 0, limit: 0 },
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: MEMBERSHIP_API,
    PATH: 'member/details',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: MEMBERSHIP_API,
    RESPONSE: {},
    DATA: { todos: [], total: 0, skip: 0, limit: 0 },
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: DEPENDENT_API,
    PATH: 'member/dependent',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: MEMBERSHIP_API,
    RESPONSE: {},
    DATA: { todos: [], total: 0, skip: 0, limit: 0 },
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: TOKEN_GENERATION_API,
    PATH: 'generate-token',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: TOKEN_GENERATION_API,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: TOKEN_VERIFY_API,
    PATH: 'verify-token',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: TOKEN_VERIFY_API,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: GET_DASHBOARD_SETTINGS,
    PATH: 'dashboard/settings/:uid',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: GET_DASHBOARD_SETTINGS,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: SAVE_DASHBOARD_SETTINGS,
    PATH: 'dashboard/settings',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: GET_DASHBOARD_SETTINGS,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: GET_ADMIN_USER,
    PATH: 'admin/users',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: GET_ADMIN_USER,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: GET_ADMIN_TPA_DATA,
    PATH: 'admin/tpaData',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: GET_ADMIN_TPA_DATA,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: SAVE_ADMIN_TPA_DATA,
    PATH: 'admin/userTpaData',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: SAVE_ADMIN_TPA_DATA,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: SAVE_ADMIN_USER,
    PATH: 'admin/users',
    METHOD: API_TYPE.PUT,
    AFTER_ACTION: SAVE_ADMIN_USER,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: GET_ADMIN_USER_TPA_DATA,
    PATH: 'admin/userTpaData',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: GET_ADMIN_USER_TPA_DATA,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: DELETE_ADMIN_USER,
    PATH: 'admin/users/:userId',
    METHOD: API_TYPE.DELETE,
    AFTER_ACTION: DELETE_ADMIN_USER,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: CREATE_ADMIN_USER,
    PATH: 'admin/users',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: CREATE_ADMIN_USER,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: ERROR_TRENDS_MTD_API,
    PATH: 'dashboard/eligibilityErrorsTrends',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: ERROR_TRENDS_MTD_API,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: ELIGIBILITY_ERRORS_MTD_API,
    PATH: 'dashboard/eligibilityErrors',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: ELIGIBILITY_ERRORS_MTD_API,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: PAYER_FILE_RATIO_MTD_API,
    PATH: 'dashboard/memberErrorRatio',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: PAYER_FILE_RATIO_MTD_API,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: SAVE_ADMIN_USER_TPA_DATA,
    PATH: 'admin/tpaData',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: SAVE_ADMIN_USER_TPA_DATA,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: GET_MEMBER_PRODUCTS,
    PATH: 'member/product',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: GET_MEMBER_PRODUCTS,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
];

export default APIS;
