import React from 'react';
import UserInformation from "./UserInformation"
import { underscoreToCamel } from "../../../Utils/Common/common.util"
import {
  Modal,
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import Icon from "../../Common/Icon"
import {MEMBERSHIP_DEPENDENTS_MODAL} from "../../../Constants/ComponentConstants/componentConstants"
import {modalStyle} from "../../../Constants/constants"
const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
    borderShadow: "none !important",
  },
};
const DependentModal = (props) => {
  const { open, close } = props
  const {TITLE:{DEPENDENTS}} = MEMBERSHIP_DEPENDENTS_MODAL
  const handleClose = () => { close(false); }
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (val) => {
    setExpanded(expanded === val ? "" : val);
  };
  const setDependent = (i, dep) => {
    const expStr = `panel${i + 1}`
    if (expanded && expanded === expStr) {
      // alert("selected dependend")
      handleClose()
      handleChange(expStr)
      props.setDependent(dep)
    }

  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        fullWidth maxWidth="md"
      >
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid xs={11}>
              <Typography id="modal-title" variant="h6" component="h6">
                <b>{DEPENDENTS}</b>
              </Typography>
            </Grid>
            <Grid style={{ textAlign: 'right', cursor: 'pointer' }} xs={1}>
              <Icon name="CloseIcon" onClick={handleClose} />
            </Grid>
            {
              props?.dependent?.length > 0 ?
                props?.dependent?.map((dependent, i) => {
                  const { name } = dependent
                  return (<Accordion
                    sx={AccordionStyle}
                    style={{ width: '100%' }}
                    expanded={expanded === `panel${i + 1}`}
                    className={`p-10 ${expanded === `panel${i + 1}` ? 'user-info-content-selected' : 'user-info-content'}`}
                    key={`user-info-acc-secondary_information${i}`}
                  >
                    <AccordionSummary
                      className="user-display-accordian"
                      expandIcon={
                        expanded === `panel${i + 1}` ? (
                          <Icon name="RemoveIcon" onClick={() => handleChange(`panel${i + 1}`)} />
                        ) : (
                            <Icon name="AddIcon" onClick={() => handleChange(`panel${i + 1}`)} />
                          )
                      }
                      aria-controls={`panel${i + 1}-content`}
                      id={`panel${i + 1}-header`}
                    >
                      <Typography style={expanded?{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }:{}} onClick={() => setDependent(i, dependent)}>{underscoreToCamel(name)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <UserInformation
                          accordian={true.toString()}
                          title={underscoreToCamel(name)}
                          key={`user-info-Address${i}`}
                          userData={dependent}
                          excludeKey={['resourceId', 'parentResourceId']}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>)
                }) :
                <Grid item xs={12}>
                  No dependent found
                </Grid>
            }
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DependentModal;
