import Icon from "../../Common/Icon"

const DeleteButtonRenderer = (params) => {    
    
    return (<>
        <Icon name="DeleteIcon" 
            onClick={() => params.handleDelete(params.data)}
        />
    </>)
}

export default DeleteButtonRenderer