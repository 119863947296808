import { MEMBERSHIP_SEARCH_API } from '../../../Constants/actionTypes';

export const membershipSearchReducer = (state = null, action) => {
  switch (action.type) {
    case MEMBERSHIP_SEARCH_API:
      return action.payload;
    default:
      return state;
  }
};
