import React from 'react';
import "../../../Assets/Css/SearchBox.css";
import MembershipSearchResults from './MembershipSearchResults';
import {MEMBERSHIP_SEARCH} from "../../../Constants/ComponentConstants/componentConstants"

const SearchResults = () => {
    return (
        <>
            <div className="searchtitle">{MEMBERSHIP_SEARCH.TITLE}</div>
            <div className="line"></div>
            <MembershipSearchResults />
        </>
    );
};

export default SearchResults;