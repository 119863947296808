import React from "react"
import Icon from "../../Common/Icon"

const AdminAndSecuritySetup = (props) => {
    const { links } = props;
    return (<>
        {/* <Icon name="LockOutlinedIcon" className="dashboard-icons" /> */}
        {
            links.map(link => {
                return (
                    <p key={JSON.stringify(link)} id={`admin-security-setup-p-${props.currIndex}`}>
                        <a id={`admin-security-setup-a-${props.currIndex}`}
                            className={`widget-anchor`} href={`${link.url}`}>
                            {link.text}<Icon name="ArrowOutwardIcon" />
                        </a>
                    </p>

                )
            })
        }
    </>)
}

export default AdminAndSecuritySetup