import { AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect } from 'react';
import MultipleSelectCheckmarks from './MultipleSelectCheckmarks';
import { Button } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { connect } from "react-redux"
import {
    getUserTpaDataActions,
    getTpaDataActions,
    saveTpaDataActions,
    saveUserTpaDataActions,
} from '../../../Redux/Actions/Admin/adminAction';
import { getDifferance, securitySavePayload } from "../../../Utils/AdminAndSecurity/adminSecurity.util"
import { ADMIN_SECURITY } from "../../../Constants/ComponentConstants/componentConstants"
import TPAModal from './TPAModal';

const SecuritySettings = (props) => {
    const [isEditable, setIsEditable] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [apiRowData, setApiRowData] = useState([]);
    const [openTPAModal, setOpenTPAModal] = useState(false)
    const {
        getUsersTPA,
        getTPA,
        get_user_tpa,
        get_tpa,
        saveTPA,
        saveUserTPA,
    } = props

    const handleEditableButtonClick = () => {
        setIsEditable(prev => {
            if (prev) {
                const diff = getDifferance(rowData, apiRowData)
                if (diff?.length > 0) {
                    diff?.forEach(elm => {
                        saveTPA(securitySavePayload(elm))
                    })
                }
            }
            return !prev
        });
    }


    useEffect(() => {
        if (get_user_tpa?.error || get_tpa?.error) return
        setRowData((get_user_tpa || [])?.reduce((rowAccu, { userId, assigned=[] }) => {
            if (userId) rowAccu.push(
                {
                    User: userId, AssignedTpaIds: assigned?.split(",")?.reduce((acc, elm) => {
                        if ((get_tpa || [])?.map(e => e.tpaId)?.includes(elm)) acc.push(elm)
                        return acc
                    }, [])
                }
            )
            return rowAccu
        }, []))
        setApiRowData(rowData)
    }, [get_user_tpa, get_tpa])

    const updateTPA = (value, data) => {
        rowData.map(tpa => {
            if (tpa.User === data.User) {
                tpa.AssignedTpaIds = value
            }
            return tpa
        })
        setRowData(row => row)
    }
    useEffect(() => {
        getUsersTPA()
        getTPA()
    }, [])
    const gridOptions = {
        rowHeight: 60
    };
    const colDefs = [
        { field: 'User', editable: false },
        {
            field: 'AssignedTpaIds',
            cellRenderer: MultipleSelectCheckmarks,
            cellRendererParams: {
                isEditable: isEditable,
                security_settings_tpa: get_tpa,
                updateTPA: updateTPA
            }
        }
    ]



    return (
        <div>
            <div style={{ display: 'flex', width: "90vw", justifyContent: 'right', marginBottom: '10px' }}>
                <Button variant="outlined" onClick={handleEditableButtonClick}>
                    {isEditable ? 'Save Settings' : 'Edit Settings'}
                </Button>
            </div>
            <div
                className={
                    "ag-theme-quartz"
                }
                style={{ width: '90vw', height: '400px' }}
            >
                <AgGridReact
                    gridOptions={gridOptions}
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100
                    }}
                />
                {isEditable && <Button variant="outlined" onClick={() => setOpenTPAModal(res => !res)} style={{ marginTop: '10px' }}>{ADMIN_SECURITY?.SECURITY_SETTINGS?.Add_TPA_ID}</Button>}

            </div>
            <TPAModal
                open={openTPAModal}
                title={ADMIN_SECURITY?.SECURITY_SETTINGS?.Add_TPA_ID}
                getUsersTPA={getTPA}
                saveUserTPA={saveUserTPA}
                close={setOpenTPAModal} />
        </div>
    );
};


const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getUsersTPA: getUserTpaDataActions,
    getTPA: getTpaDataActions,
    saveTPA: saveTpaDataActions,
    saveUserTPA: saveUserTpaDataActions,
};

export default connect(mapStateToProps, mapActionToProps)(SecuritySettings);