import React from "react"
import Draggable from 'react-draggable';
import WidgetCard from "./WidgetCard"
const DraggableCard = (props) => {
    const {
        currentElement,
        currIndex,
        handleOnStart,
        handleOnStop,
        idStartWith,
        isDisable,
    } = props
    return (<>
        {
            isDisable ?
                <div className={`widget-container`} id={`${idStartWith}-${currIndex}-container-${idStartWith}`}>
                    <div
                        id={`${idStartWith}-${currIndex}-body-${idStartWith}`}
                        className={`handler-${idStartWith}-${currIndex}`}>
                        <WidgetCard {...props} />
                    </div>
                </div>
                :
                <Draggable
                    key={`${idStartWith}-draggableItem-${currIndex}-${JSON.stringify(currentElement)}-draggable`}
                    handle={`.handler-${idStartWith}-${currIndex}`}
                    onStart={() => handleOnStart(currentElement, currIndex)}
                    onStop={handleOnStop}>
                    <div className={`widget-container`} id={`${idStartWith}-${currIndex}-container-${idStartWith}`}>
                        <div
                            id={`${idStartWith}-${currIndex}-body-${idStartWith}`}
                            className={`handler-${idStartWith}-${currIndex} widget-container`}>
                            <WidgetCard {...props} />
                        </div>
                    </div>

                </Draggable>
        }
    </>)
}

export default DraggableCard