import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button
} from '@mui/material';
import Icon from "../../Common/Icon"
import {modalStyle} from "../../../Constants/constants"

const TPAModal = (props) => {
  const { open, close, title, getUsersTPA, saveUserTPA } = props
  const [tpaId, setTpaId] = React.useState('')
  const [tpaName, setTpaName] = React.useState('')
 
  const submitHandler = async (e) => {
    e.preventDefault()
    await saveUserTPA({tpaId, tpaName})
    setTpaId('')
    setTpaName('')
    await getUsersTPA()
    close(res => !res)
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        fullWidth maxWidth="md"
      >
        <Box component="form" onSubmit={submitHandler} sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid xs={11}>
              <Typography id="modal-title" variant="h6"
                align="left"
                sx={{ marginTop: '-1px', color: 'rgb(3, 92, 103)' }}>
                <b>{title}</b>
              </Typography>
            </Grid>
            <Grid style={{ textAlign: 'right', cursor: 'pointer' }} xs={1}>
              <Icon name="HighlightOffRoundedIcon" onClick={() => close(res => !res)} />
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={12}>
              <FormControl fullWidth size="small">
                <div className="add-tpa-modal-input">
                  <TextField required onChange={e => setTpaId(e?.target?.value)} label="TPA ID" sx={{ width: '90%', color: 'rgb(3, 92, 103)' }} />
                  <TextField required onChange={e => setTpaName(e?.target?.value)} label="TPA Name" sx={{ width: '90%', marginLeft: '5%', color: 'rgb(3, 92, 103)' }} />
                </div>
              </FormControl>
            </Grid>
            <Grid sx={{ textAlign: 'right', marginTop: '10px' }} xs={12}>
              <Button sx={{ backgroundColor: 'rgb(3, 92, 103)', color: 'white' }} type="submit">Submit</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default TPAModal;
