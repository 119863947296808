import { COMPONENT_INPUT } from '../../../Constants/actionTypes';

export const inputAction = (input = {}) => {
  return (dispatch) => {
    dispatch({
      type: COMPONENT_INPUT,
      payload: {
        input,
      },
    });
  };
};
