import {
  GET_DASHBOARD_SETTINGS,
  SAVE_DASHBOARD_SETTINGS,
} from '../../../Constants/actionTypes';

export const dashboardSettingsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SETTINGS:
      return action?.payload[0]?.layout || {};
    case SAVE_DASHBOARD_SETTINGS:
      return state;
    default:
      return state;
  }
};
