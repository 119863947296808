import React from 'react';
import {
  OutlinedInput, 
  InputLabel, 
  MenuItem, 
  FormControl, 
  ListItemText, 
  Select, 
  Checkbox
} from '@mui/material';

import {ADMIN_SECURITY} from "../../../Constants/ComponentConstants/componentConstants"
import {getTPAName} from "../../../Utils/AdminAndSecurity/adminSecurity.util"


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectCheckmarks = (props) => {
  const {security_settings_tpa=[], data, updateTPA, isEditable} = props
  const [val, setVal] = React.useState(data.AssignedTpaIds)
  const handleChange = (evt) =>{
    const {target: {value}} = evt
    setVal([...[],...value])
    updateTPA(value, data)
  }
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} size="small" FullWidth>
        <InputLabel id="demo-multiple-checkbox-label">{ADMIN_SECURITY.SECURITY_SETTINGS.TAG}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={val || []}
          onChange={handleChange}
          input={<OutlinedInput label={ADMIN_SECURITY.SECURITY_SETTINGS.TAG} />}
          renderValue={(selected) => getTPAName(selected, security_settings_tpa)}
          MenuProps={MenuProps}
          disabled={!isEditable}
        >
          {security_settings_tpa?.map(({tpaName, tpaId}) => (
            <MenuItem key={`tpa-${tpaId}`} value={tpaId}>
              <Checkbox checked={val?.includes(tpaId) } />
              <ListItemText primary={tpaName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}



export default MultipleSelectCheckmarks;