import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import Icon from "../Common/Icon"
import "../../Assets/Css/BasicSearchScreen.css";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '85%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};


const CustomModal = ({ open, handleClose, title, content }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Icon name="CloseIcon" />
        </IconButton>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: ' #035c67' }}>
          {title}
        </Typography>
        <Box sx={{ mt: 2 }}>
          {content}
        </Box>
      </Box>

    </Modal>
  );
};

export default CustomModal;