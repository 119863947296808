import React from "react"
import WIDGET_COMPONENT, { isWidget } from "../../Constants/widgetConstant"

const Widget = ({ component, currIndex }) => {
    if (isWidget(component)) {
        const WidgetItem = WIDGET_COMPONENT[component].component
        const widgetProps = { ...WIDGET_COMPONENT[component], currIndex: currIndex }
        return <WidgetItem {...widgetProps} />
    }
    else
        return (<></>)
}

export default Widget