import React from "react"
import { Grid } from "@mui/material";
import GridContainer from "./GridContainer"

const DashboardGrid = (props) => {
    const {
        activeWidget,
        idStartWith,
    } = props
    return (<Grid item xs={4}>
        {
            activeWidget?.map((currentElement, currIndex) => {
                return (
                    <GridContainer
                        key={`${idStartWith}-draggableItem-${currIndex}-${JSON.stringify(currentElement)}`}
                        {...props}
                        currentElement={currentElement}
                        currIndex={currIndex}
                    />
                )
            })
        }
    </Grid>)
}

export default DashboardGrid