import React from "react";
import { Grid, Typography } from "@mui/material";
import { underscoreToFirstCharCapital } from "../../../Utils/Common/common.util";
import "../../../Assets/Css/UserInformationScreen.css"
const UserInformation = (props) => {
  const {userData,excludeKey,accordian, title } = props
  return (
    <div className={!accordian? 'container subscriber-container':''}>
      {!accordian?<Typography>{title}</Typography>: <></>}
      <Grid className={!accordian? 'user-info-content':''} container xs={12}>
        {Object.keys(userData || {}).map((e, i) => {
          return (
            <>{ (excludeKey || []).indexOf(e) === -1 && typeof userData[e] === "string" ? 
            <Grid
              className={`pd-20`}
              key={`user-data-${e}--${i}`}
              item={'true'}
              xs={(i % 5) % 2 === 0 ? 2 : 3}
            >
              <div style={{ color: "#808080" }}>{underscoreToFirstCharCapital(e)}</div>
              <div>{userData[e]}</div>
            </Grid> : <></>}</>
          );
        })}
      </Grid>
    </div>
  );
};

export default UserInformation;
