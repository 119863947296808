import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { getPrimarySubscriberAction, getDependentAction } from "../../../Redux/Actions/Membership/membershipAction"
import UserInformation from "./UserInformation"
import { emptyObject, underscoreToCamel } from "../../../Utils/Common/common.util"
import Loading from "../../Common/Loading";
import {ROUTES} from "../../../Constants/routesConstant"

const SubscriberDetails = (props) => {
  const navigate = useNavigate();
  const { selectedUser, membership } = props;
  const [showLoading, setShowLoading] = useState(false)
  const {DASHBOARD} = ROUTES
  const getData = async () => {
    setShowLoading(true)
    if (selectedUser.type === "primary")
      await props.getPrimarySubscriberData(selectedUser)
    else
      await props.getDependentData(selectedUser)
    setShowLoading(false)
  }
  if (emptyObject(selectedUser || {}))
    navigate(DASHBOARD)

  useEffect(() => {
    getData()
  }, [selectedUser])


  return (
    <>
      {showLoading ? <Loading /> : <>
        {emptyObject(selectedUser) ? <></> :
          <Grid className="pb-35" container spacing={2}>
            <h1 className="container">{membership.name}</h1>
            {selectedUser.type === "primary" ? <UserInformation
              accordian={false}
              title={underscoreToCamel('subscriber_information')}
              key={`user-info-subscriber_information`}
              userData={membership['subscriber_information']}
            /> : <UserInformation
                accordian={false}
                title={underscoreToCamel('primary_information')}
                key={`user-info-primary_information`}
                userData={membership['primary_information']}
              />}
            <UserInformation
              accordian={false}
              title={underscoreToCamel('Address')}
              key={`user-info-Address`}
              userData={membership['Address']}
            />
            {selectedUser.type === "primary" ? <UserInformation
              accordian={false}
              title={underscoreToCamel('additional_information')}
              key={`user-info-additional_information`}
              userData={membership['additional_information']}
            /> : <UserInformation
                accordian={false}
                title={underscoreToCamel('secondary_information')}
                key={`user-info-secondary_information`}
                userData={membership['secondary_information']}
              />}
          </Grid>
        }
      </>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  getPrimarySubscriberData: getPrimarySubscriberAction,
  getDependentData: getDependentAction
};

export default connect(mapStateToProps, mapActionToProps)(SubscriberDetails);
